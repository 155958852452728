<template lang="pug">
  vx-card(
    id="card-new"
    style="position:relative"
    class="pt-4"
  )
    div.pt-3(
      v-if="filterOptions"
      class="ag-grid-table-actions"
    )
      slot(
        name="filterOptions"
      )
      vs-divider
    div(
      id="header-table"
      class="flex flex-wrap justify-between items-center"
      v-show="showHeader"
    )
      div(
        class="mt-2 mb-4 md:mb-0 mr-1 ag-grid-table-actions-left"
      )
        PaginationTopShow(
          v-if="showPaginationTopTable"
          @updatePageShow="updatePageShow"
        )
      div(
        class="mt-2 mb-4 md:mb-0 mr-1 ag-grid-table-actions-left"
      )
        PaginationTop(
          v-if="showPaginationTop"
          :pagesInfo="pagesInfo"
          :pageSizeOptions="pageSizeOptions"
          @updatePageSize="updatePageSize"
        )
        ShowColumn(
          v-if="showColumn"
          :columnData="getStateData"
          @asignColumnDataShow="asignColumnDataShow"
        )
        PinnedColumn(
          v-if="showPinnedColumn"
          ref="pinnedColumn"
          :columnData="getStateData"
          @asignColumnDataPin="asignColumnDataPin"
        )
        SizeFields(
          v-if="showAutoSizeAll"
          @autoSizeAll="autoSizeAll"
        )
      div(
        v-if="centerOptions"
        class="ag-grid-table-actions"
      )
        slot(
          name="centerOptions"
        )
      slot(
        name="actionBar"
      )
      div(
        v-if="isSeach"
        class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
      )
        vs-input(
          class="mb-4 md:mb-0 mr-1"
          icon="search"
          :placeholder="$t('servicios_tab.search')"
          class="FatherClass"
          v-model="searchQuery"
          @input="updateSearchQuery"
        )
    ag-grid-vue(
      :style="styleProp"
      :class="classProp"
      :rowAnimation="true"
      :columnDefs="columnData"
      :defaultColDef="defaultColDef"
      :colResizeDefault="colResizeDefault"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :pagination="pagination"
      :paginationPageSize="paginationPageSize"
      :paginationAutoPageSize="paginationAutoPageSize"
      :animateRows="animateRows"
      :suppressRowClickSelection="suppressRowClickSelection"
      :suppressClickEdit="suppressClickEdit"
      :floatingFilter="floatingFilter"
      :suppressPaginationPanel="true"
      :headerHeight="40"
      :getRowHeight="getRowHeight"
      :rowSelection="rowSelection"
      :rowMultiSelectWithClick="true"
      :context="context"
      :frameworkComponents="frameworkComponents"
      :singleClickEdit="singleClickEdit"
      @first-data-rendered="onFirstDataRenderedTable"
      @gridColumnsChanged="gridColumnsChanged"
      @rowDataChanged="rowDataChangedTable"
      @filterChanged="filterChanged"
      @cellClicked="cellClicked"
      @rowSelected="rowSelected"
      @selectionChanged="selectionChanged"
      @cellEditingStopped="cellEditingStopped"
    )
    vs-pagination(
      v-if="pagination"
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    )
    p(
      style='position:absolute;right:0;bottom:0;margin-bottom:3em;margin-right:2em;font-size:12px'
    ) {{ selectedServicesInfo }}
</template>
<script>
import Vue from "vue"
import { AgGridVue } from "ag-grid-vue"
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss"
import FieldsAgGridTable from "@/fields/ag-grid/fieldsAgGridTable"
import PaginationTopShow from "@/components/tableAgGrid/PaginationTopShow"
import PaginationTop from "@/components/tableAgGrid/PaginationTop"
import SizeFields from "@/components/tableAgGrid/SizeFields"
import ShowColumn from "@/components/tableAgGrid/ShowColumn"
import PinnedColumn from "@/components/tableAgGrid/PinnedColumn"
import ActionCellRouterLeft from "@/components/tableAgGrid/ActionCellRouterLeft"
import actionsServices from "@/components/tableAgGrid/actionsServices"
import actionsCompany from "@/components/companies/actionsCompanies"
import actionsStatus from "@/components/status/actionsStatus"
import actionsSms from "@/components/sms/actionsSms"
import actionsUsers from "@/components/users/actionsUsers"
import actionsRoles from "@/components/roles/actionsRoles"
import actionsWhatsapp from "@/components/whatsapp/actionsWhatsapp"

const NAME = "AgGridTable"

export default {
  name: NAME,
  components: {
    AgGridVue,
    PaginationTop,
    SizeFields,
    ShowColumn,
    PinnedColumn,
    PaginationTopShow
  },
  props: {
    styleProp: {
      type: String,
      required: false,
      default: "width: 100%; height:50vh"
    },
    classProp: {
      type: String,
      required: false,
      default: "ag-theme-material w-100 my-4 ag-grid-table"
    },
    rowSelection: {
      type: String,
      required: false,
      default: "multiple"
    },
    columnDefs: {
      type: Array,
      required: true
    },
    rowData: {
      type: Array,
      required: true
    },
    gridOptions: {
      type: Object,
      required: false
    },
    selectedServicesInfo: {
      type: String,
      required: false
    },
    pagination: {
      type: Boolean,
      required: false,
      default: true
    },
    paginationAutoPageSize: {
      type: Boolean,
      required: false,
      default: false
    },
    animateRows: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeTofit: {
      type: Boolean,
      required: false,
      default: false
    },
    isSeach: {
      type: Boolean,
      required: false,
      default: true
    },
    suppressRowClickSelection: {
      type: Boolean,
      required: false,
      default: false
    },
    cellClicked: {
      type: Function,
      required: false,
      default: () => {}
    },
    rowSelected: {
      type: Function,
      required: false,
      default: () => {}
    },
    selectionChanged: {
      type: Function,
      required: false,
      default: () => {}
    },
    onFirstDataRendered: {
      type: Function,
      required: false,
      default: () => {}
    },
    colResizeDefault: {
      type: String,
      required: false,
      default: "shift"
    },
    floatingFilter: {
      type: Boolean,
      required: false,
      default: true
    },
    rowDataChanged: {
      type: Function,
      required: false,
      default: () => {}
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: () => true
    },
    centerOptions: {
      type: Boolean,
      required: false,
      default: () => true
    },
    filterOptions: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showPaginationTop: {
      type: Boolean,
      required: false,
      default: () => true
    },
    showPaginationTopTable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showColumn: {
      type: Boolean,
      required: false,
      default: () => true
    },
    showPinnedColumn: {
      type: Boolean,
      required: false,
      default: () => true
    },
    showAutoSizeAll: {
      type: Boolean,
      required: false,
      default: () => true
    },
    cellEditingStopped: {
      type: Function,
      required: false,
      default: () => {}
    },
    suppressClickEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    singleClickEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      pinned: null,
      context: null,
      frameworkComponents: null,
      searchQuery: "",
      columnData: [],
      pagesInfo: "",
      pageSizeOptions: [20, 50, 100, 200, 500],
      totalRowsDisplayed: 0,
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        suppressMenu: true
      },
      settings: {
        maxScrollbarLength: 5,
        wheelSpeed: 0.6
      },
      paginationPageSize: 50,
      maxPageNumbers: 7,
      totalPages: 0,
      currentPage: 1,
      getStateData: null
    }
  },
  beforeMount() {
    this.frameworkComponents = {
      actioncellrouterleft: ActionCellRouterLeft,
      actionsservices: actionsServices,
      actionscompany: actionsCompany,
      actionsstatus: actionsStatus,
      actionssms: actionsSms,
      actionsusers: actionsUsers,
      actionsroles: actionsRoles,
      actionswhatsapp: actionsWhatsapp
    }
  },
  created() {
    this.context = { componentParent: this }
    this.gridOptions.localeText = this.asignLocalText()
  },
  mounted() {
    this.columnData = this.columnDefs
    Vue.nextTick(() => this.gridOptions.api.sizeColumnsToFit())
    this.asignColumnData(this.columnData)
  },
  watch: {
    columnDefs(val) {
      this.asignColumnData(val)
      this.getPaginationData()
    },
    "$i18n.locale"() {
      this.asignColumnData(this.columnDefs)
      this.gridOptions.localeText = this.asignLocalText()

      this.gridOptions.api.showNoRowsOverlay()
      this.getPagesInfo()
      this.$emit("RefreshTableData")
    },
    currentPage(val) {
      this.gridOptions.api.paginationGoToPage(val - 1)
      this.getPagesInfo()
    }
  },
  methods: {
    updatePageShow(val) {
      this.$emit("updatePageShowTable", val)
    },
    getRowHeight() {
      return 40
    },
    asignColumnDataPin(keyShow, hide) {
      this.gridOptions.columnApi.setColumnPinned(keyShow, hide)
      this.gridOptions.api.sizeColumnsToFit()
      let allColumnIds = []
      this.gridOptions.columnApi.getAllColumns().forEach(column => {
        allColumnIds.push(column.colId)
      })
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
    },
    asignColumnDataShow(keyShow, hide) {
      this.gridOptions.columnApi.setColumnVisible(keyShow, hide)
      this.gridOptions.api.sizeColumnsToFit()
      let allColumnIds = []
      this.gridOptions.columnApi.getAllColumns().forEach(column => {
        allColumnIds.push(column.colId)
      })
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
    },
    asignColumnData(data) {
      this.columnData = data.map(column => {
        let translate = ""
        if (column.noLanguage) {
          translate = column.traslateName[this.$i18n.locale]
        }
        if (typeof column.traslateName === "object") {
          translate = column.traslateName[this.$i18n.locale]
        } else {
          translate = `${this.$t(column.traslateName)}`
        }
        return {
          ...column,
          headerName: translate
        }
      })
    },
    asignLocalText() {
      let translatedText = {}
      for (const key in FieldsAgGridTable) {
        translatedText[key] = this.$t(FieldsAgGridTable[key])
      }
      return translatedText
    },
    updateSearchQuery(val) {
      this.gridOptions.api.setQuickFilter(val)
    },
    getPaginationData() {
      this.paginationPageSize = this.gridOptions.api.paginationGetPageSize()
      this.totalPages = this.gridOptions.api.paginationGetTotalPages()
      this.currentPage = this.gridOptions.api.paginationGetCurrentPage() + 1
    },
    getStateDataColumn() {
      this.getStateData = this.gridOptions.columnApi.getColumnState()
      this.getStateData = this.getStateData.map(column => {
        let indexFound = this.columnData.find(columnIndex => columnIndex.field == column.colId)
        if (indexFound) {
          return {
            ...column,
            hide: (column.hide = !column.hide),
            traslateName: indexFound
          }
        }
        return {
          ...column,
          hide: (column.hide = !column.hide)
        }
      })
    },
    gridColumnsChanged() {
      if (this.sizeTofit) {
        this.gridOptions.api.sizeColumnsToFit()
      }
      this.getStateDataColumn()
    },
    getPagesInfo() {
      this.totalRowsDisplayed = this.gridOptions.api.getDisplayedRowCount()
      this.pagesInfo = `${this.currentPage * this.paginationPageSize - (this.paginationPageSize - 1)} -
        ${
          this.totalRowsDisplayed - this.currentPage * this.paginationPageSize > 0
            ? this.currentPage * this.paginationPageSize
            : this.totalRowsDisplayed
        }
        ${this.$t("of")} ${this.totalRowsDisplayed}`
    },
    updatePageSize(val) {
      this.gridOptions.api.paginationSetPageSize(val)
      this.paginationPageSize = val
      this.totalPages = this.gridOptions.api.paginationGetTotalPages()
      this.currentPage = this.gridOptions.api.paginationGetCurrentPage() + 1
      this.getPagesInfo()
    },
    rowDataChangedTable(params) {
      this.totalPages = this.gridOptions.api.paginationGetTotalPages()
      this.getPagesInfo()
      this.rowDataChanged(params)
    },
    filterChanged() {
      this.getPaginationData()
      this.getPagesInfo()
    },
    autoSizeAll() {
      this.setSize = !this.setSize
      if (this.setSize) {
        let allColumnIds = []
        this.gridOptions.columnApi.getAllColumns().forEach(column => {
          allColumnIds.push(column.colId)
        })
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
      } else {
        this.gridOptions.api.sizeColumnsToFit()
      }
    },
    onFirstDataRenderedTable(params) {
      params.api.sizeColumnsToFit()
      params.api.setFloatingFiltersHeight(40)
      this.onFirstDataRendered(params)
    }
  }
}
</script>
<style>
#header-table {
  /* height: 70px; */
}

#header-table .vs-con-input-label {
  margin: 0 !important;
}

.ag-cell {
  line-height: 40px !important;
}

.ag-cell-label-container {
  height: 100%;
}

.ag-floating-filter-body {
  height: 80%;
}

.ag-grid-table .ag-filter-filter {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* .ag-header-cell {
  padding-left: 5px !important;
  padding-right: 2px !important;
} */

.ag-floating-filter-button {
  margin-left: 1% !important;
}

.ag-grid-table-actions {
  flex: 1;
  height: 100%;
}
#header-table > div.flex.flex-wrap.items-center.justify-between.ag-grid-table-actions-right > div > div > input {
  border-radius: 9999px !important;
}
#card-new > div.vx-card__collapsible-content.vs-con-loading__container > div {
  padding: 0rem 1rem 2rem 1rem;
}
.vs-pagination--li.is-current {
  color: black;
}
</style>