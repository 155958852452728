import Vue from "vue"

export default Vue.extend({
  template: `
    <div style="display: flex">
      <vs-button
        v-if="$can('update', 'sms')"
        radius
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-edit"
        @click="setEditSms"
      ></vs-button>
      <vs-button
        v-if="$can('delete', 'sms')"
        radius
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-trash-2"
        @click="setDeleteSms"
      ></vs-button>
    </div>
    `,
  data() {
    return {
      value: null
    }
  },
  methods: {
    setEditSms () {
      if (this.params.data.id_sms == null) {
        this.$vs.notify({
          text: this.$t("status.nonEditableStatus"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      } else this.$store.dispatch('sms/set_sms', this.params.data)
    },
    setDeleteSms () {
      this.$store.dispatch('sms/set_delete_sms', this.params.data)
    }
  }
})
