import Vue from "vue"
import JwtService from "@/jwt-service"

export default Vue.extend({
  template: `
      <a @click="routeLeaft" class="cursor-pointer" style="color: #7873af">
          {{this.value}}
      </a>
    `,
  data () {
    return {
      value: null
    }
  },
  mounted() {
    this.value = this.valueServiceId()
  },
  methods: {
    routeLeaft() {
      this.$socket.emit('generateServiceSummaryPdf', {
        token: JwtService.getToken(),
        service_id: this.params.data.id
      })
      this.$vs.notify({
        text: this.$t('create_services.generate_servicio'),
        color: '#64cc52',
        iconPack: 'feather',
        icon:'icon-loader'
      })
    },
    valueServiceId() {
      return this.params.data.properties.service_order
    }
  }
});
