import Vue from "vue"

export default Vue.extend({
  template: `
    <div style="display: flex">
      <vs-button
        v-if="$can('change', 'permission')"
        radius
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-edit"
        @click="setEditRoles"
      ></vs-button>
      <vs-button
        v-if="$can('delete', 'permission')"
        radius
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-trash-2"
        @click="setDeleteRoles"
      ></vs-button>
    </div>
    `,
  data() {
    return {
      value: null
    }
  },
  methods: {
    setEditRoles () {
      this.$store.dispatch('roles/set_roles', this.params.data)
    },
    setDeleteRoles () {
      this.$store.dispatch('roles/set_delete_roles', this.params.data)
    }
  }
})
