import Vue from "vue"
import JwtService from "@/jwt-service"
import HTTP from "@/http-common"

export default Vue.extend({
  template: `
      <a @click="routeLeaft" class="cursor-pointer" style="color:#7873af">
          {{ this.value }}
      </a>
    `,
  data() {
    return {
      value: null
    }
  },
  mounted() {
    this.value = this.valueRouteNum()
  },
  methods: {
    routeLeaft() {
      let route_num = this.params.data.hasOwnProperty('route_num') ? this.params.data.route_num : this.params.data.properties.route_num
      if (route_num > 1000) {
        if (this.params.data.properties.vehicle != null) {
          this.$socket.emit('generatePdf', {
            token: JwtService.getToken(),
            date: this.params.data.properties.date,
            plate: this.params.data.properties.vehicle.plate,
            service_id: this.params.id
          })
          this.$vs.notify({
            text: this.$t('create_services.generate_leaft'),
            color: '#64cc52',
            iconPack: 'feather',
            icon: 'icon-loader'
          })
        }
      } else {
        if (this.$store.state.idRouter) {
          this.$socket.emit('generatePdf', {
            token: JwtService.getToken(),
            route_id: this.$store.state.idRouter,
            route_num: route_num
          })
          this.$vs.notify({
            text: this.$t('create_services.generate_leaft'),
            color: '#64cc52',
            iconPack: 'feather',
            icon: 'icon-loader'
          })
        } else {
          HTTP.get(`services/getreport?id_service=${this.params.data.id}`).then((response) => {
            this.$socket.emit('generatePdf', {
              token: JwtService.getToken(),
              route_id: response.data,
              route_num: route_num
            })
            this.$vs.notify({
              text: this.$t('create_services.generate_leaft'),
              color: '#64cc52',
              iconPack: 'feather',
              icon: 'icon-loader'
            })
          })
        }
      }
    },
    valueRouteNum() {
      if (this.params.data.hasOwnProperty('route_num')) {
        return this.params.data.route_num
      } else {
        return this.params.data.properties.route_num == 0 ? this.params.data.id : this.params.data.properties.route_num
      }
    }
  }
})
