import Vue from "vue"

export default Vue.extend({
  template: `
    <div style="display: flex">
      <vs-button
        v-if="$can('update', 'users')"
        radius
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-edit"
        @click="setEditUser"
      ></vs-button>
      <vs-button
        v-if="$can('update', 'users')"
        radius
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-map"
        @click="setMapUser"
      ></vs-button>
    </div>
    `,
  data() {
    return {
      value: null
    }
  },
  methods: {
    setEditUser () {
      this.$store.dispatch('userService/set_user', this.params.data)
    },
    setMapUser () {
      this.$store.dispatch('userService/set_user_map', this.params.data)
    }
  }
})
